import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

const Home = () => {
  const [curNow, setNow] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(5000);
  // Home panel
  // auto-refresh
  const refreshHome = () => {
    // update time
    setNow(format(new Date().toLocaleString(), 'eee HH:mm:ss'));
  }

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(refreshHome, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);



  return (
    <div className="container">
    <div className='row'>
        <span className="col-sm-7 biginfo detailtxt">
          {curNow}
      </span>
        <span className="col-sm-5  ">
          Cherry2024 Remote Control
      </span>
      </div>
    </div>
  )
}

export default Home;
