import React, { useEffect, useState } from "react";
import { FaCalendarCheck, FaQuestion, FaMoon, FaRunning, FaToggleOff, FaToggleOn, FaSync } from "react-icons/fa";
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { PrivateRoute } from './_components';
import { HomePage } from './HomePage';
import { LoginPage } from './LoginPage';
import logo from './logo.svg';
import './App.css';
import FarmPanel from './Farm';
import HomePanel from './Home';
import LoginPanel from './Login';
import WipPanel from './Wip';

function App() {

  const [data, setData] = useState(null);
  const [srvrhide, setHide] = useState(false);
  const [schedhide, setSched] = useState(false);
  const [allowed, setAllowed] = useState(false);

  const srvrHandler = () => {
    setHide(!srvrhide)
  }
  const schedHandler = () => {
    setSched(!schedhide)
  }

  useEffect(() => {
    fetch(`https://ctrl2.cherry2024.com:2096/getservers`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="App">
      <main className="App-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              {data ?
                <div className="container">
                  <div className="row">
                    <div className="col-sm-4  border-top border-bottom border-start  border-end border-warning">
                      <HomePanel />
                    </div>
                    <span className="col-sm-8 border-end border-top border-bottom  border-start border-warning">
                      <div className="container ">
                        <div className="row ">
                          <div className="col-sm-4  d-flex align-items-center justify-content-center">
                            <a target="_blank" href="http://dash.cherry2024.com:7004/public-dashboards/5cbdf8fa852f44f7b32a4c08a006bd86" className="btn btn-primary btn-sm active" role="button">Live Farms Dashboard</a>
                          </div>
                          <div className="col-sm-4 shorttext">
                            <span className="togglecheck ">
                              <input
                                type="checkbox"
                                id="detailctrl"
                                checked={srvrhide}
                                onChange={srvrHandler}
                              />
                            </span>&nbsp;&nbsp;
                            <label htmlFor="detailctrl">
                              {srvrhide
                                ? <span><span className="toggle up cursortoggle"><FaToggleOn /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle">Hide Bots</span> </span>
                                : <span><span className="toggle detailtxt cursortoggle"><FaToggleOff /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle">Show Bots</span> </span>
                              }
                            </label>
                          </div>
                          <div className="col-sm-4 shorttext">
                            <span className="togglecheck ">
                              <input
                                type="checkbox"
                                id="schedctrl"
                                checked={schedhide}
                                onChange={schedHandler}
                              />
                            </span>&nbsp;&nbsp;
                            <label htmlFor="schedctrl">
                              {schedhide
                                ? <span><span className="toggle up cursortoggle"><FaToggleOn /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle">Hide Schedule </span> </span>
                                : <span><span className="toggle detailtxt cursortoggle"><FaToggleOff /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle"> Show Schedule </span> </span>
                              }
                            </label>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="row">
                    <span className="col-sm-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={1}
                        farmname={"Onsite"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"Farm6"}
                      />
                    </span>
                    <span className="col-sm-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={2}
                        farmname={"Limitless"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"Limitless"}
                      />
                    </span>
                    <span className="col-sm-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={3}
                        farmname={"Logistics"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"Farm6"}
                      />
                    </span>
                  </div>
                  <div className="row">
                    <span className="col-sm-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={4}
                        farmname={"Dolphin"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"Dolphin"}
                      />
                    </span>
                    <span className="col-sm-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={5}
                        farmname={"Spanish"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"Spanish"}
                      />
                    </span>
                    <span className="col-sm-4">
                      &nbsp;
                      {(srvrhide) && <div className="padit align-items-center justify-content-center border border-info detailhdr">
                        <FaRunning />=The Bot is running normally. There are no overrides in effect.
                      </div>
                      }
                      {(srvrhide) &&
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          <span className="up" >on</span>/<span className="down" >off</span>=The Bot is under the control of this panel. This control only lasts for today.
                          Tomorrow, the normal schedule will be applied.
                        </div>
                      }
                      {(srvrhide) &&
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          To refresh all the bots, Hide and then Show the Bots. This will refresh the current remote control status for
                          each Bot. You can also refesh each Bot individually (&nbsp;<FaSync />&nbsp;).
                        </div>
                      }


                      {(schedhide) && <div className="padit align-items-center justify-content-center border border-info detailhdr">
                        Date Symbols: <br></br>&nbsp;
                        <span className="calon justify-content-center runningman shorttext col-sm-3">
                          < FaRunning />&nbsp;=&nbsp;ON
                        </span> &nbsp;
                        <span className=" caloff justify-content-center runningman shorttext col-sm-3">
                          < FaMoon />&nbsp;=&nbsp;OFF
                        </span> <br></br>
                        <span className="calnot justify-content-center runningman shorttext col-sm-6">
                          < FaQuestion />&nbsp;=&nbsp;NO&nbsp;SCHEDULE
                        </span>
                      </div>
                      }
                      {(schedhide) &&
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          <FaCalendarCheck />&nbsp;This is a rolling 3 month calendar always starting on today.<br></br>
                          <FaCalendarCheck />&nbsp;Clicking on a date toggles it on and off.<br></br>
                          <FaCalendarCheck />&nbsp;Click on a < FaQuestion /> = NO SCHEDULE&nbsp;
                          day to set the robot to run on that date. This is how you add new dates to the schedule for a farm.<br></br>
                          <FaCalendarCheck />&nbsp;If today has NO SCHEDULE, the bot will run on a fixed Mon-Sat schedule.<br></br>
                          <FaCalendarCheck />&nbsp;Robots start at 5am and stop at 9pm.<br></br>
                        </div>
                      }

                    </span>
                  </div>
                </div>
                : 'Loading...'}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;

