import React, { useEffect, useState } from 'react';
import { FaRunning } from "react-icons/fa";
import { format } from 'date-fns';
import { FaPlay, FaSquareFull, FaSync, FaGhost } from "react-icons/fa";

const Dash = ({ ip, farmno, botno }) => {
  const [robostat, setRobo] = useState(null);
  const [robomsg, setMsg] = useState("Loading...");
  const fetchMetrics = () => {
    // retrieve and then setData()
    fetch(`https://treea${botno}.cherry2024.com:2096/serverBots`, { signal: AbortSignal.timeout(3000) })
      .then(response => response.json())
      .then(robostat => setRobo(robostat))
      .catch(error => setMsg(`Akamai Server Treea${botno} is Down`));
  }

  useEffect(() => {
    fetchMetrics();
  }, []);

  // Bot Detail control
  function botstop(farm, botno) {
    // setStat3(botno);
    // setStat2(`${new Date().toLocaleString()} Stopping`);
    //fetchMetrics();
    fetch(`https://treea${botno.slice(-2)}.cherry2024.com:2096/cherryoff?farmno=${farm}`)
      .then(response => response.json())
      .then(fetchMetrics())
      .catch(error => console.error(error));
  }
  function botstart(farm, botno) {
    // setStat3(botno);
    // setStat2(`${new Date().toLocaleString()} Starting`);
    //fetchMetrics();
    fetch(`https://treea${botno.slice(-2)}.cherry2024.com:2096/cherryon?farmno=${farm}`)
      .then(response => response.json())
      .then(fetchMetrics())
      .catch(error => console.error(error));
  }

  return (
    <div className="shortline">
      {robostat ?
        <div>
          <span className="detailtxt">
            <button className="farmbuttonspacing btn btn-sm btn-outline-info up" title={botno + " START"} onClick={() => botstart(farmno, botno)}>Start&nbsp;&nbsp;<FaPlay /></button>
            <button className="farmbuttonspacing btn btn-sm btn-outline-info down" title={botno + " STOP"} onClick={() => botstop(farmno, botno)}>Stop&nbsp;&nbsp;&nbsp;<FaSquareFull /></button>
            &nbsp;
          </span>
          <span className="runningman detailtxt"><FaRunning /></span>
          <span>
            {JSON.parse(robostat[0].json_result)
              .filter(server => server.farm === farmno)
              .map((item, index) => (
                <span key={item.stat} className=" biginfo" title={format(new Date(item.stat.split(":")[0] * 1000).toString(), 'eee HH:mm')}>
                  {(item.stat.split(":")[1] === 'on') && <span className="up dashstat" >&nbsp;{item.stat.split(":")[1]}</span>}
                  {(item.stat.split(":")[1] === 'off') && <span className="down dashstat" >&nbsp;{item.stat.split(":")[1]}</span>}
                </span>
              ))
            }
          </span>
          &nbsp;&nbsp;<button className="btn btn-sm fresh" title="REFRESH" onClick={() => fetchMetrics()}>&nbsp;<FaSync />&nbsp;</button>
        </div>
        :
        <span className="row">
          
          {(robomsg==="Loading...")
            ? <span>{robomsg}</span>
            : <span><FaGhost />&nbsp;{robomsg}&nbsp;<FaGhost /> </span>
          }

          
         </span>
      }
    </div>

  );
};

export default Dash;


